import React from "react";
import { useAppSelector } from "../../../ecclesia/hooks";
import { isSuperUser, selectUser } from "../../../user/User";
import ListPermissionByEmailList from "../Permissions/ListPermissionByEmailList";
import { Link } from "react-router-dom";
import ListContentFeed from "../ContentFeed/ListContentFeed";
import { ExportAppMessages } from "./ExportAppMessages";

const SuperuserHome: React.FC = () => {
    return (
        <>
            <h3 className="pt-4">App Messages</h3>
            <ExportAppMessages />
            <h3 className="pt-4">Permission group management</h3>
            <ListPermissionByEmailList />
            <Link className="text-muted" to="/addpermissionbyemaillist">
                Add new permission group
            </Link>
        </>
    );
};

const AddContentFeed: React.FC = () => {
    return (
        <Link className="text-muted" to="/addcontentfeed">
            Add new content feed
        </Link>
    );
};

const AdminHome: React.FC = () => {
    const state = useAppSelector(selectUser);
    const isSuper = isSuperUser(state);

    return (
        <>
            <div>
                <h3 className="pt-4">Content Editing</h3>
                <ListContentFeed />
            </div>
            <div>{isSuper ? <AddContentFeed /> : <></>}</div>
            <div>{isSuper ? <SuperuserHome /> : <></>}</div>
        </>
    );
};

export default AdminHome;
