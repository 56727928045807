import { v4 } from "uuid";
import { Content, ContentIn, ContentOut } from "../user/contentfeed";
import axios from "axios";
import { ApiAxiosRequestConfig } from "../user/JWT";

export const putContent = async (feed_id: string, content: ContentOut) => {
    const resp = await axios.put<Content>(
        `/api/v1/content/content_feed/${feed_id}/content/${content.id}`,
        content,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return true;
    }
    return false;
};

export const contentInToContent = (content: ContentIn): Content => {
    return {
        ...content,
        live_from: new Date(content.live_from),
        created: new Date(content.created),
        updated: new Date(content.updated),
        live_until: content.live_until ? new Date(content.live_until) : null,
    };
};

export const getContent = async (feed_id: string, id: string): Promise<Content | undefined> => {
    const resp = await axios.get<ContentIn>(
        `/api/v1/content/content_feed/${feed_id}/content/${id}`,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return contentInToContent(resp.data);
    }
    return undefined;
};

export const deleteContent = async (feed_id: string, id: string) => {
    const resp = await axios.delete<Content>(
        `/api/v1/content/content_feed/${feed_id}/content/${id}`,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return true;
    }
    return false;
};

export const createContent = async (feed_id: string) => {
    const newData: ContentOut = {
        id: v4(),
        title: "Untitled",
        precis: "",
        html_content: "",
        sort_order: 10,
        draft: true,
        live_from: new Date(),
        live_until: null,
    } as const;

    await putContent(feed_id, newData);

    return newData.id;
};
