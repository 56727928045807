import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { useGetConfigQuery } from "./api/api";
import AddContentFeed from "./components/Routes/ContentFeed/AddContentFeed";
import ManageContentFeed from "./components/Routes/ContentFeed/ManageContentFeed";
import ManageContent from "./components/Routes/ContentFeed/ManageContent";
import ForgotPassword from "./components/Routes/ForgotPassword";
import Home from "./components/Routes/Home/Home";
import NotFound from "./components/Routes/NotFound";
import AddPermissionByEmailList from "./components/Routes/Permissions/AddPermissionByEmailList";
import ManagePermissionByEmailList from "./components/Routes/Permissions/ManagePermissionByEmailList";
import Register from "./components/Routes/Register";
import ResetPassword from "./components/Routes/ResetPassword";
import VerifyEmail from "./components/Routes/VerifyEmail";
import { UserProvider } from "./components/UserProvider";
import ScrollToTop from "./util/Scroll";
import engb from "date-fns/locale/en-GB";
import { registerLocale } from "react-datepicker";

// react-datepicker does this slightly horribly.. oh well
registerLocale("uk", engb);

function RouterComponent() {
    const { error, isLoading } = useGetConfigQuery();

    if (error || isLoading) {
        return <div></div>;
    }

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/verify/:token" element={<VerifyEmail />} />
                <Route path="/resetpassword/:token" element={<ResetPassword />} />
                <Route path="/permissionbyemaillist/:id" element={<ManagePermissionByEmailList />} />
                <Route path="/addpermissionbyemaillist/" element={<AddPermissionByEmailList />} />
                <Route path="/addcontentfeed/" element={<AddContentFeed />} />
                <Route path="/contentfeed/:id" element={<ManageContentFeed />} />
                <Route path="/contentfeed/:feed_id/content/:id" element={<ManageContent />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

function App() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Anglican Diocese of Perth: App Admin</title>
            </Helmet>
            <UserProvider>
                <RouterComponent />
            </UserProvider>
        </HelmetProvider>
    );
}

export default App;
