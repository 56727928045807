import React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import { createContent } from "./ContentProvider";

export const NewContentButton: React.FC<React.PropsWithChildren<{ feed_id: string }>> = ({ feed_id, children }) => {
    const navigate = useNavigate();

    const makeNew = async () => {
        const id = await createContent(feed_id);
        navigate(`/contentfeed/${feed_id}/content/${id}`);
    };

    return (
        <Button color={"success"} onClick={makeNew}>
            {children}
        </Button>
    );
};

export default NewContentButton;
