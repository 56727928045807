import axios from "axios";
import { ApiAxiosRequestConfig } from "./JWT";
import { contentInToContent } from "../components/ContentProvider";

export interface ContentFeedListing {
    id: string;
    title: string;
    slug: string;
    permission_id?: string;
}

export interface ContentIn {
    id: string;
    title: string;
    precis: string;
    html_content: string;
    sort_order: number;
    draft: boolean;
    created: string;
    updated: string;
    live_from: string;
    live_until: string | null;
}

export interface Content {
    id: string;
    title: string;
    precis: string;
    html_content: string;
    sort_order: number;
    draft: boolean;
    created: Date;
    updated: Date;
    live_from: Date;
    live_until: Date | null;
}

export interface ContentOut {
    id: string;
    title: string;
    precis: string;
    html_content: string;
    sort_order: number;
    draft: boolean;
    live_from: Date;
    live_until: Date | null;
}

export interface ContentFeedIn {
    id: string;
    title: string;
    slug: string;
    content: Array<ContentIn>;
}

export interface ContentFeed {
    id: string;
    title: string;
    slug: string;
    content: Array<Content>;
}

export const listContentFeed = async () => {
    const resp = await axios.get<ContentFeedListing[]>(`/api/v1/content/content_feed`, ApiAxiosRequestConfig());
    if (resp.status === 200) {
        return resp.data;
    }
    return [];
};

export const getContentFeed = async (id: string): Promise<ContentFeed | undefined> => {
    const resp = await axios.get<ContentFeedIn>(`/api/v1/content/content_feed/${id}`, ApiAxiosRequestConfig());
    if (resp.status === 200) {
        return { ...resp.data, content: resp.data.content.map(contentInToContent) };
    }
    return undefined;
};

export const deleteContentFeed = async (id: string) => {
    const resp = await axios.delete<ContentFeedListing>(`/api/v1/content/content_feed/${id}`, ApiAxiosRequestConfig());
    if (resp.status === 200) {
        return resp.data;
    }
    return undefined;
};

export const putContentFeed = async (list: ContentFeedListing) => {
    const resp = await axios.put<ContentFeedListing>(
        `/api/v1/content/content_feed/${list.id}`,
        list,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return resp.data;
    }
    return undefined;
};
