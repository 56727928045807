import { v4 } from "uuid";
import React from "react";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import useInput from "../../../util/useInput";
import Header from "../../Header";
import { putPermissionList } from "../../../user/Permissions";
import { useNavigate } from "react-router-dom";

const AddPermissionByEmailList: React.FC<React.PropsWithChildren<unknown>> = () => {
    const slug = useInput("");
    const title = useInput("");
    const navigate = useNavigate();

    const addPermissionGroup = () => {
        async function add() {
            const id = v4();
            await putPermissionList({
                id,
                slug: slug.value,
                title: title.value,
                email_array: [],
            });
            navigate(`/permissionbyemaillist/${id}`);
        }
        add();
    };

    return (
        <>
            <Header />
            <Container id="main">
                <h3>Add a new permission group</h3>
                <Form>
                    <FormGroup md={3}>
                        <Label for="slug">Shortcode (slug)</Label>
                        <Input id="slug" type="text" {...slug} />
                        <Label for="title">Title</Label>
                        <Input id="title" type="text" {...title} />
                        <Button
                            className="mt-2"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                addPermissionGroup();
                            }}
                        >
                            Add Permission Group
                        </Button>
                    </FormGroup>
                </Form>
            </Container>
        </>
    );
};

export default AddPermissionByEmailList;
