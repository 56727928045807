import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Table } from "reactstrap";
import { ContentFeed, deleteContentFeed, getContentFeed } from "../../../user/contentfeed";
import Header from "../../Header";
import { isSuperUser, selectUser } from "../../../user/User";
import { useAppSelector } from "../../../ecclesia/hooks";
import NewContentButton from "../../NewContentButton";

const RemoveContentFeedButton: React.FC<{ id: string }> = ({ id }) => {
    const navigate = useNavigate();

    const remove = () => {
        if (window.confirm("Are you sure you want to remove this content feed? This cannot be undone.")) {
            deleteContentFeed(id);
            navigate("/");
        }
    };

    return (
        <Button
            color="danger"
            onClick={(e) => {
                e.preventDefault();
                remove();
            }}
        >
            Remove content feed
        </Button>
    );
};

const ManageContentFeed: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [feed, setFeed] = React.useState<ContentFeed>();
    const state = useAppSelector(selectUser);
    const isSuper = isSuperUser(state);
    const { id } = useParams();

    React.useEffect(() => {
        async function get() {
            if (!id) {
                return;
            }
            const data = await getContentFeed(id);
            setFeed(data);
        }
        get();
    }, [id]);

    if (!feed || !id) {
        return <></>;
    }

    const tableRows = feed.content.map((list) => (
        <tr key={list.id}>
            <td>
                <Link to={"/contentfeed/" + id + "/content/" + list.id}>{list.title}</Link>
            </td>
            <td>{list.draft ? "Yes" : "No"}</td>
            <td className={list.draft ? "draft-td" : ""}>{list.live_from.toLocaleString()}</td>
            <td className={list.draft ? "draft-td" : ""}>{list.live_until ? list.live_until.toLocaleString() : ""}</td>
        </tr>
    ));

    return (
        <>
            <Header />
            <Container id="main">
                <h3>Content feed: {feed.title}</h3>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Draft?</th>
                            <th>Go-live date/time</th>
                            <th>Expiry date/time</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </Table>
                <NewContentButton feed_id={id}>New content</NewContentButton>
                {isSuper ? <RemoveContentFeedButton id={id} /> : <></>}
            </Container>
        </>
    );
};

export default ManageContentFeed;
