import React from "react";
import { Button } from "reactstrap";
import { ApiAxiosRequestConfig } from "../../../user/JWT";
import axios from "axios";

const saveAppMessages = async () => {
    const resp = await axios.get<string>("/api/v1/export/app-messages", ApiAxiosRequestConfig());
    if (resp.status === 200) {
        var element = document.createElement("a");
        const now = new Date();
        element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(resp.data));
        element.setAttribute("download", `pdt-app-messages-${now.toISOString()}.csv`);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
};

export const ExportAppMessages: React.FC = () => {
    return (
        <div>
            <p>
                These are messages received from unauthenticated users of app. No attempt is made at validating details
                such as name and email address, so they should be treated as unverified.
            </p>
            <Button onClick={saveAppMessages}>Export to CSV</Button>
        </div>
    );
};
