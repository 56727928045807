import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";
import { PermissionByEmailList, listPermissionLists } from "../../../user/Permissions";

const ListPermissionByEmailList: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [lists, setLists] = React.useState<PermissionByEmailList[]>([]);
    React.useEffect(() => {
        async function get() {
            const data = await listPermissionLists();
            setLists(data);
        }
        get();
    }, []);

    const tableRows = lists.map((list) => (
        <tr key={list.id}>
            <td>
                <Link to={"/permissionbyemaillist/" + list.id}>{list.title}</Link>
            </td>
        </tr>
    ));

    return (
        <Table striped>
            <thead>
                <tr>
                    <th>Title</th>
                </tr>
            </thead>
            <tbody>{tableRows}</tbody>
        </Table>
    );
};

export default ListPermissionByEmailList;
