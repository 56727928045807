import React from "react";
import Select from "react-select";
import { listPermissionLists } from "../../../user/Permissions";

export const PermissionSelector: React.FC<
    React.PropsWithChildren<{
        val: string | undefined;
        setVal: React.Dispatch<React.SetStateAction<string | undefined>>;
    }>
> = ({ val, setVal }) => {
    const [options, setOptions] = React.useState<Array<{ value: string; label: string }>>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        async function fetch() {
            const lists = await listPermissionLists();
            setOptions(
                lists.map((list, idx) => {
                    return {
                        value: list.id,
                        label: list.title,
                    };
                }),
            );
            setIsLoading(false);
        }
        fetch();
    }, [setOptions]);

    return (
        <Select
            isLoading={isLoading}
            options={options}
            onChange={(selected) => {
                if (selected) {
                    setVal(selected.value);
                } else {
                    setVal(undefined);
                }
            }}
        />
    );
};
