import { Col, Container, Row } from "reactstrap";
import React from "react";

export const Footer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <Container className="footer-padding border-top" sm={{ offset: 2, size: 10 }}>
            <Row className="pt-4">
                <Col sm={{ size: 6, offset: 3 }}>
                    <h5>About</h5>
                    <ul className="list-unstyled text-small">
                        <li>
                            <a href="https://perth.anglican.org/">Dioesan Website</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};
