import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Input, Row, Table } from "reactstrap";
import { validate as uuidValidate } from "uuid";
import {
    PermissionByEmailList,
    deletePermissionList,
    getPermissionList,
    putPermissionList,
} from "../../../user/Permissions";
import { Footer } from "../../Footer";
import Header from "../../Header";

function validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

const ManagePermissionByEmailList: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [list, setList] = React.useState<PermissionByEmailList | undefined>();
    const [addEmail, setAddEmail] = React.useState<string>("");
    const [mutated, setMutated] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function get(id: string) {
            const data = await getPermissionList(id);
            setList(data);
        }
        if (id) {
            get(id);
        }
    }, [id]);

    React.useEffect(() => {
        async function save() {
            if (list) {
                putPermissionList(list);
            }
        }
        if (list && mutated) {
            save();
        }
    }, [mutated, list]);

    const deleteList = () => {
        async function del() {
            if (list) {
                await deletePermissionList(list.id);
                navigate("/");
            }
        }
        if (window.confirm("Are you sure you want to delete this permission list?")) {
            del();
        }
    };

    if (!id || !uuidValidate(id)) {
        return <p>Invalid ID</p>;
    }

    const removeEmail = (idx: number) => {
        if (!list) {
            return;
        }
        const newEmails = list.email_array.filter((email, i) => i !== idx);
        setMutated(true);
        setList({ ...list, email_array: newEmails });
    };

    const doAddEmail = () => {
        if (!list) {
            return;
        }
        // add the email address if it's not already in the list
        if (!list.email_array.includes(addEmail)) {
            const newEmails = [...list.email_array, addEmail];
            setMutated(true);
            setList({ ...list, email_array: newEmails });
        }
        setAddEmail("");
    };

    const sortedEmails = list?.email_array.sort((a, b) => a.localeCompare(b));
    const tableRows = sortedEmails?.map((email, idx) => (
        <tr key={idx}>
            <td>{email}</td>
            <td>
                <Button
                    color="danger"
                    onClick={() => {
                        removeEmail(idx);
                    }}
                >
                    <FontAwesomeIcon icon={faRemove} />
                </Button>
            </td>
        </tr>
    ));

    return (
        <>
            <Header />
            <Container id="main">
                <h1 className="mt-4">Manage Permission List</h1>
                <p>
                    You are editing permission list <strong>{list?.title}</strong>, with slug{" "}
                    <strong>{list?.slug}</strong>.
                </p>
                <p>{list?.email_array.length} users are members of this permission group:</p>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Email address</th>
                            </tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </Table>
                </Row>
                <Row>
                    <p>Add a user to this permission group:</p>
                </Row>
                <Form>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                            <Input
                                type="text"
                                placeholder="Email address"
                                value={addEmail}
                                onChange={(e) => setAddEmail(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                disabled={!validateEmail(addEmail)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    doAddEmail();
                                }}
                            >
                                Add Email
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                        <h1 className="mt-4">Delete Permission List</h1>
                        <p>Warning - this cannot be reversed.</p>
                    </Col>
                </Row>
                <Form>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                            <Button
                                color="danger"
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteList();
                                }}
                            >
                                Delete permission list
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Footer />
        </>
    );
};

export default ManagePermissionByEmailList;
