import { Row, Button, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Login from "../../Login";

const WelcomeBox: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Row>
            <p className="lead">Anglican Diocese of Perth: App administration</p>
        </Row>
    );
};

const LoginBox: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Row>
            <p className="text-center">Already have an account? Sign in.</p>
            <Login />
            <p className="text-center">
                <Link to="/forgotpassword">Forgotten password?</Link>
            </p>
            <div className="d-grid gap-2">
                <hr style={{ margin: "0 0 1rem 0" }} />
                <Button color="success" tag={Link} to="/register">
                    Sign up
                </Button>
            </div>
        </Row>
    );
};

const GuestHome: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <>
            <Container id="main">
                <Row>
                    <Col md={{ size: 6, offset: 3 }}>
                        <WelcomeBox />
                        <LoginBox />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default GuestHome;
