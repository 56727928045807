import axios from "axios";
import { ApiAxiosRequestConfig } from "./JWT";

export interface PermissionByEmailList {
    id: string;
    slug: string;
    title: string;
    email_array: string[];
}

export const listPermissionLists = async () => {
    const resp = await axios.get<PermissionByEmailList[]>(
        `/api/v1/permissions/permission_by_email_list`,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return resp.data;
    }
    return [];
};

export const getPermissionList = async (id: string) => {
    const resp = await axios.get<PermissionByEmailList>(
        `/api/v1/permissions/permission_by_email_list/${id}`,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return resp.data;
    }
    return undefined;
};

export const deletePermissionList = async (id: string) => {
    const resp = await axios.delete<PermissionByEmailList>(
        `/api/v1/permissions/permission_by_email_list/${id}`,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return resp.data;
    }
    return undefined;
};

export const putPermissionList = async (list: PermissionByEmailList) => {
    const resp = await axios.put<PermissionByEmailList>(
        `/api/v1/permissions/permission_by_email_list/${list.id}`,
        list,
        ApiAxiosRequestConfig(),
    );
    if (resp.status === 200) {
        return resp.data;
    }
    return undefined;
};
