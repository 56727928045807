import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { v4 } from "uuid";
import { putContentFeed } from "../../../user/contentfeed";
import { PermissionSelector } from "./PermissionSelector";
import useInput from "../../../util/useInput";
import Header from "../../Header";

const AddContentFeed: React.FC<React.PropsWithChildren<unknown>> = () => {
    const slug = useInput("");
    const title = useInput("");
    const navigate = useNavigate();
    const [listId, setListId] = React.useState<string | undefined>(undefined);

    const addContentFeed = () => {
        async function add() {
            if (!listId) {
                return;
            }
            const id = v4();
            await putContentFeed({
                id,
                permission_id: listId,
                slug: slug.value,
                title: title.value,
            });
            navigate(`/contentfeed/${id}`);
        }
        add();
    };

    return (
        <>
            <Header />
            <Container id="main">
                <h3>Add a new content feed</h3>
                <Form>
                    <FormGroup md={3}>
                        <Label for="slug">Shortcode (slug)</Label>
                        <Input id="slug" type="text" {...slug} />
                        <Label for="title">Title</Label>
                        <Input id="title" type="text" {...title} />
                        <Label for="permission-list">Permission group</Label>
                        <PermissionSelector val={listId} setVal={setListId} />
                        <Button
                            disabled={!slug.value || !title.value || !listId}
                            className="mt-2"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                addContentFeed();
                            }}
                        >
                            Add Content Feed
                        </Button>
                    </FormGroup>
                </Form>
            </Container>
        </>
    );
};

export default AddContentFeed;
