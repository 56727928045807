import { Container } from "reactstrap";
import { useAppSelector } from "../../../ecclesia/hooks";
import { isAdminUser, selectUser } from "../../../user/User";
import AdminHome from "./AdminHome";

function UserHome() {
    const state = useAppSelector(selectUser);
    const isAdmin = isAdminUser(state);

    return (
        <>
            <Container id="main">
                <h1 className="display-5">Welcome, {state.user?.name}.</h1>
                <div>{isAdmin ? <AdminHome /> : <></>}</div>
            </Container>
        </>
    );
}

export default UserHome;
