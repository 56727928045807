import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, FormGroup, Label, Input, ButtonGroup } from "reactstrap";
import { Content, ContentFeed, ContentOut, getContentFeed } from "../../../user/contentfeed";
import { deleteContent, getContent, putContent } from "../../ContentProvider";
import DatePicker from "react-datepicker";
import Header from "../../Header";

const RemoveContentButton: React.FC<{ feed_id: string; id: string }> = ({ feed_id, id }) => {
    const navigate = useNavigate();

    const remove = () => {
        if (window.confirm("Are you sure you want to remove this content? This cannot be undone.")) {
            deleteContent(feed_id, id);
            navigate(`/contentfeed/${feed_id}`);
        }
    };

    return (
        <Button
            color="danger"
            onClick={(e) => {
                e.preventDefault();
                remove();
            }}
        >
            Remove content
        </Button>
    );
};

const ManageContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const navigate = useNavigate();
    const [content, setContent] = React.useState<Content>();
    const [contentFeed, setContentFeed] = React.useState<ContentFeed>();
    const { feed_id, id } = useParams();

    React.useEffect(() => {
        async function get() {
            if (!id || !feed_id) {
                return;
            }
            const cData = await getContent(feed_id, id);
            setContent(cData);
            const cfData = await getContentFeed(feed_id);
            setContentFeed(cfData);
        }
        get();
    }, [id, feed_id]);

    const doSave = async (feed_id: string, content: ContentOut) => {
        await putContent(feed_id, content);
        navigate(`/contentfeed/${feed_id}`);
    };

    const save = () => {
        if (!content || !feed_id) {
            return;
        }
        doSave(feed_id, content);
    };

    if (!content || !contentFeed || !feed_id || !id) {
        return <></>;
    }

    return (
        <>
            <Header />
            <Container id="main">
                <h3>
                    Editing content "{content.title}" in{" "}
                    <span
                        className="feed-link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/contentfeed/${contentFeed.id}`);
                        }}
                    >
                        {contentFeed.title}
                    </span>
                </h3>
                <form>
                    <FormGroup>
                        <Label for="title">
                            Title (indexed content such as studies and prayers will be sorted by title)
                        </Label>
                        <Input
                            id="title"
                            name="title"
                            value={content.title}
                            onChange={(event) => {
                                setContent({ ...content, title: event.target.value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="precis">
                            Precis (a short description of the post, used on some index listings)
                        </Label>
                        <Input
                            id="precis"
                            name="precis"
                            value={content.precis}
                            onChange={(event) => {
                                setContent({ ...content, precis: event.target.value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">Sort order (most content has order 10, and is then sorted by title)</Label>
                        <Input
                            id="sort_order"
                            name="sort_order"
                            value={content.sort_order}
                            onChange={(event) => {
                                setContent({ ...content, sort_order: Number.parseInt(event.target.value) || 10 });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="liveFrom">Live from:</Label>
                        <DatePicker
                            locale="uk"
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            id="liveFrom"
                            selected={content.live_from}
                            onChange={(date) => {
                                if (date) {
                                    setContent({ ...content, live_from: date });
                                }
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="liveUntil">Live until:</Label>
                        <DatePicker
                            className="form-control"
                            id="liveFrom"
                            locale="uk"
                            dateFormat="dd/MM/yyyy"
                            selected={content.live_until}
                            inline={false}
                            onChange={(date) => {
                                if (date) {
                                    setContent({ ...content, live_until: date });
                                }
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="checkbox"
                            checked={content.draft}
                            onChange={(event) => {
                                if (event) {
                                    console.log(event);
                                    setContent({ ...content, draft: event.target.checked });
                                }
                            }}
                        />{" "}
                        <Label check for="liveUntil">
                            Draft?
                        </Label>
                    </FormGroup>
                </form>
                <Input
                    type="textarea"
                    name="html_content"
                    id="html_content"
                    value={content.html_content}
                    rows={20}
                    onChange={(event) => {
                        setContent({ ...content, html_content: event.target.value });
                    }}
                />
                <ButtonGroup>
                    <Button
                        color="success"
                        onClick={(e) => {
                            e.preventDefault();
                            save();
                        }}
                    >
                        Save Content
                    </Button>
                </ButtonGroup>
                <RemoveContentButton feed_id={feed_id} id={id} />
            </Container>
        </>
    );
};

export default ManageContent;
